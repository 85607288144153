import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatMenuModule,
  MatIconModule,
  MatSidenavModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatDividerModule,
  MatListModule,
  MatCardModule,
  MatSelectModule,
  MatSliderModule,
  MatDatepickerModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PmpBarChartComponent } from './pmp-bar-chart/pmp-bar-chart.component';
import { AlertBarComponent } from './alert-bar/alert-bar.component';
import { OlMapComponent } from './ol-map/ol-map.component';
import { MarkersDirective } from '@core/directives/markers.directive';
import { PolygonsDirective } from '@core/directives/polygons.directive';
import { EditableMarkerDirective } from '@core/directives/editable-marker.directive';
import { EditablePolygonDirective } from '@core/directives/editable-polygon.directive';
import { MapPopupDirective } from '@core/directives/map-popup.directive';
import { MatNativeDateModule } from '@angular/material/core';
import { Ng5SliderModule } from 'ng5-slider';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    PmpBarChartComponent,
    AlertBarComponent,
    OlMapComponent,
    MarkersDirective,
    PolygonsDirective,
    EditableMarkerDirective,
    EditablePolygonDirective,
    MapPopupDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatTabsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSelectModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    Ng5SliderModule,
    MatTableModule,
    MatSlideToggleModule
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    AlertBarComponent,
    OlMapComponent,
    MarkersDirective,
    PolygonsDirective,
    EditableMarkerDirective,
    EditablePolygonDirective,
    MapPopupDirective,
    MatSelectModule,
    MatSliderModule,
    MatDatepickerModule,
    Ng5SliderModule,
    MatTableModule,
    MatSlideToggleModule
  ]
})
export class SharedModule {}
