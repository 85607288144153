import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from '@state/index'; // Load the reducer
import { isLoggedIn, isLoggingIn, loginErrMsg } from '@state/reducers'; // Load the reducer
import * as actions from '@state/actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoggedIn: Observable<boolean>;
  isLoggingIn: Observable<boolean>;
  loginError: Observable<string>;
  constructor(public router: Router, private store: Store<State>) {}

  ngOnInit() {
    this.isLoggedIn = this.store.pipe(select(isLoggedIn));
    this.isLoggingIn = this.store.pipe(select(isLoggingIn));
    this.loginError = this.store.pipe(select(loginErrMsg));
    this.isLoggedIn.subscribe((loggedIn) => {
      if (loggedIn) {
        const redirectURL = sessionStorage.getItem('redirectUrl');
        this.router.navigate([redirectURL ? redirectURL : '/']);
      }
    });
  }

  onLogin() {
    this.store.dispatch(new actions.Login());
  }
}
