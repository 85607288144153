import { PressureMonitor, UnserviceableArea } from '@models';
import { PMPActions, PMPActionTypes } from '../actions/pmp.actions';

export const pmpFeatureKey = 'pmp';

/**
 * Pattern Flow:
 * 1. Actions are dispatched
 * 2. Effects route the action to its respective service
 * 3. Reducers consume the result of that
 */
export interface State {
  pmps: PressureMonitor[] | null;
  newPmp: PressureMonitor | null;
  selectedPMP: PressureMonitor|null;
  errorMessage: string;
  successMessage: string;
  isLoading: boolean;
  isFiltering: boolean;
  isSaving: boolean;
  isDeleting: boolean;
}

export const initialState: State = {
  pmps: null,
  newPmp: null,
  selectedPMP: null,
  errorMessage: '',
  successMessage: '',
  isLoading: false,
  isFiltering: false,
  isSaving: false,
  isDeleting: false
};

export function reducer(state = initialState, action: PMPActions): State {
  switch (action.type) {
    case PMPActionTypes.GetPMP:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isLoading: true,
        isFiltering: false
      };
    case PMPActionTypes.GetPMPSuccess:
      return {
        ...state,
        pmps: action.payload,
        errorMessage: '',
        successMessage: '',
        isLoading: false
      };
    case PMPActionTypes.GetPMPFail:
      return {
        ...state,
        pmps: null,
        errorMessage: action.payload,
        successMessage: '',
        isLoading: false
      };
    case PMPActionTypes.SetPMPVisibility:
      return {
        ...state,
        isFiltering: true
      };
    case PMPActionTypes.SetPMPVisibilityDone:
      return {
        ...state,
        pmps: action.payload,
        isFiltering: false
      };
    case PMPActionTypes.SavePMP:
      return {
        ...state,
        newPmp: null,
        errorMessage: '',
        successMessage: '',
        isSaving: true
      };
    case PMPActionTypes.SavePMPSuccess:
      return {
        ...state,
        newPmp: action.payload,
        errorMessage: '',
        successMessage: 'Pressure Monitor data saved',
        isSaving: false
      };
    case PMPActionTypes.SavePMPFail:
      return {
        ...state,
        newPmp: null,
        errorMessage: action.payload,
        successMessage: '',
        isSaving: false
      };
    case PMPActionTypes.DeletePMP:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isDeleting: true
      };
    case PMPActionTypes.DeletePMPSuccess:
      return {
        ...state,
        errorMessage: '',
        successMessage: 'Pressure Monitor deleted',
        isDeleting: false
      };
    case PMPActionTypes.DeletePMPFail:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        isDeleting: false
      };
    case PMPActionTypes.SetSelectedPMP:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isLoading: true,
        isFiltering: false
      };
    case PMPActionTypes.SetSelectedPMPSuccess:
      return {
        ...state,
        selectedPMP: action.payload,
        errorMessage: '',
        successMessage: '',
        isLoading: false,
        isFiltering: false
      }
    case PMPActionTypes.SetSelectedPMPFail:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        isLoading: false,
        isFiltering: false
      }
    default:
      return state;
  }
}
