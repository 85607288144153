import { User } from '@models';
import { UserActionTypes, UserActions } from '../actions/user.actions';

export const authFeatureKey = 'user';

export interface State {
  user: User | null;
  users: User[] | null;
  errorMessage: string;
  isLoading: boolean;
  isSaving: boolean;
}

export const initialState: State = {
  user: null,
  users: null,
  errorMessage: '',
  isLoading: false,
  isSaving: false
}

export function reducer(state = initialState, action: UserActions): State {
  switch(action.type) {
    case UserActionTypes.GetUsers:
      return {
        ...state,
        isLoading: true
      };
    case UserActionTypes.GetUsersSuccess:
      return {
        ...state,
        isLoading: false,
        users: action.users
      };
    case UserActionTypes.GetUsersFail:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    case UserActionTypes.UpdateUser:
      return {
        ...state,
        user: action.user
      };
    case UserActionTypes.UpdateUserSuccess:
      return {
        ...state,
        user: null,
        users: action.users
      };
    case UserActionTypes.UpdateUserFail:
      return {
        ...state,
        user: null,
        errorMessage: action.payload
      };
    default:
      return state;
  }
}