import { PressureMonitor, UnserviceableArea } from '@models';
import { PMPDataActions, PMPDataActionTypes } from '../actions/pmp-data.actions';

export const pmpFeatureKey = 'pmp';

/**
 * Pattern Flow:
 * 1. Actions are dispatched
 * 2. Effects route the action to its respective service
 * 3. Reducers consume the result of that
 */
export interface State {
  pmpData: PressureMonitor[] | null;
  errorMessage: string;
  successMessage: string;
  isLoading: boolean;
  isFiltering: boolean;
}

export const initialState: State = {
  pmpData: null,
  errorMessage: '',
  successMessage: '',
  isLoading: false,
  isFiltering: false
};

export function reducer(state = initialState, action: PMPDataActions): State {
  switch (action.type) {
    case PMPDataActionTypes.GetCurrentData:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isLoading: true,
        isFiltering: false
      };
    case PMPDataActionTypes.GetCurrentDataSuccess:
      return {
        ...state,
        pmpData: action.payload,
        errorMessage: '',
        successMessage: '',
        isLoading: false
      };
    case PMPDataActionTypes.GetCurrentDataFail:
      return {
        ...state,
        pmpData: null,
        errorMessage: action.payload,
        successMessage: '',
        isLoading: false
      };
    case PMPDataActionTypes.GetCurrentData:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isLoading: true,
        isFiltering: true
      };
    case PMPDataActionTypes.SetAllPMPVisibility:
      return {
        ...state,
        isFiltering: true,
        isLoading: true,
      };
    case PMPDataActionTypes.SetAllPMPVisibilitySuccess:
      return {
        ...state,
        isFiltering: false,
        isLoading: false,
        pmpData: action.payload
      };
    case PMPDataActionTypes.GetHourlyView:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isLoading: true,
        isFiltering: false
      }
    default:
      return state;
  }
}
