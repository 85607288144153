import { Directive, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import { Style, Icon } from 'ol/style'; // ,

import { uniqueID } from '@core/helper';

import { MapComponent } from '../abstract/map-component.abstract';

const idPrefix = 'my_marker_';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[app-marker], app-marker'
})
export class MarkersDirective implements OnChanges, MapComponent {
  @Input() id: string = uniqueID();
  @Input() markerIcon: string | null = null;
  @Input() point: number[] | null = null;
  @Input() additionalData: any = null;
  @Input() isHidden = false;
  // TODO: Add option here to do a normal marker with color options

  @Output() markerClick = new EventEmitter<{ id: string; coordinates: any[] }>();

  parentVector: VectorSource = null;
  feature: Feature = null;
  defaultIcon = '';

  constructor() {}

  get featureId(): string {
    return `${idPrefix}_${this.id}`;
  }

  get hasClickListener(): boolean {
    return this.markerClick.observers.length > 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach((k) => {
      if (!changes[k].firstChange) {
        // react only to recent changes
        switch (k) {
          case 'point':
          case 'markerIcon':
            // if the point ir markerIcon was changed, we need to redraw this
            this.moveLocation();
            break;
          case 'additionalData':
            this.changeMetaData();
            break;
          case 'isHidden':
            this.setHidden();
            break;
          default:
            break;
        }
      }
    });
  }

  render(vectorSource: VectorSource, defaultIcon: string) {
    this.parentVector = vectorSource;
    this.defaultIcon = defaultIcon;
    if (this.point) {
      const point = new Point(this.point);
      // TODO: Make this configurable so that we can use normal markers when we want
      // or use this icon here
      const iconStyle = this.getStyle();

      this.feature = new Feature({
        geometry: point,
        type: 'icon',
        ...this.additionalData
      });

      this.feature.setId(this.featureId);
      this.feature.setStyle(iconStyle);

      this.parentVector.addFeature(this.feature);
    }
  }

  getStyle() {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction' as any,
        anchorYUnits: 'fraction' as any,
        src: this.markerIcon || this.defaultIcon,
        scale: 0.5,
        opacity: this.isHidden ? 0 : 1
      })
    });
  }

  moveLocation() {
    // removing it first from the map and redrawing it
    if (this.parentVector && this.feature) {
      console.log('location moved', this.id);
      this.parentVector.removeFeature(this.feature);
      this.render(this.parentVector, this.defaultIcon);
    }
  }

  changeMetaData() {
    if (this.feature) {
      this.feature.setProperties(this.additionalData);
    }
  }

  setHidden() {
    if (this.feature) {
      const iconStyle = this.getStyle();
      this.feature.setStyle(iconStyle);
    }
  }

  onClick(coordinates: any[]) {
    this.markerClick.emit({ id: this.id, coordinates });
  }
}
