import { User } from '@models';
import { AuthActionTypes, AuthActions } from '../actions/auth.actions';

export const authFeatureKey = 'auth';

/**
 * Pattern Flow:
 * 1. Actions are dispatched
 * 2. Effects route the action to its respective service
 * 3. Reducers consume the result of that
 */
export interface State {
  user: User | null;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  errorMessage: string;
}

export const initialState: State = {
  user: null,
  isLoggedIn: false,
  isLoggingIn: false,
  errorMessage: ''
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.Login:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: true,
        errorMessage: ''
      };
    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        isLoggingIn: false
      };
    case AuthActionTypes.LoginFailure:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        isLoggingIn: false,
        errorMessage: action.payload
      };
    case AuthActionTypes.CheckLogin:
      return {
        ...state,
        isLoggingIn: true
      };
    case AuthActionTypes.Unauthenticated:
    case AuthActionTypes.LogoutConfirmed:
      return {
        ...state,
        user: null,
        isLoggingIn: false,
        isLoggedIn: false
      };

    default:
      return state;
  }
}
