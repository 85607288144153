import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { State } from '@app/state';
import { DataService } from '@app/core/service';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { UserActionTypes, GetUsersSuccess, GetUsersFail, UpdateUser, UpdateUserSuccess, UpdateUserFail } from '../actions/user.actions';
import { mergeMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { getUsers } from '../reducers';

@Injectable()
export class UserDataEffects {
  constructor(private store: Store<State>, private dataService: DataService, private actions: Actions) {}
  
  @Effect()
  getCurrentPMPData: Observable<Action> = this.actions.pipe(
    ofType(UserActionTypes.GetUsers),
    mergeMap(() => this.dataService.getUsers()),
    map((response) => {
        return new GetUsersSuccess(response);
    }),
    catchError((err) => of(new GetUsersFail(err.message)))
  );

  @Effect()
  saveUserData: Observable<Action> = this.actions.pipe(
    ofType(UserActionTypes.UpdateUser),
    map((action: UpdateUser) => action.user),
    withLatestFrom(this.store.pipe(select(getUsers))),
    mergeMap(async (data) => {
      const [user, users] = data;
      const updatedUserList = users.map((item) => {
        if (item.uid === user.uid)
          return user;
        else
          return item;
      });

      await this.dataService.saveUser(user);
    
      return updatedUserList;
    }),
    map((users) => new UpdateUserSuccess(users)),
    catchError((err) => of(new UpdateUserFail(err.message)))
  );
}
