import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AngularFirestore } from '@angular/fire/firestore'; // AngularFirestoreDocument

import { HttpService } from './http.service';
import { PressureMonitor, PumpHouse, APIResponse, UnserviceableArea, ISettings } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { fromLonLat } from 'ol/proj';
// temporary only
import { pmpData } from '../../data/pmpData';
import { User } from '../models';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  api = environment.API_URL;
  urls = {
    PMP: {
      current: `${this.api}/api/PMP`,
      getall: `${this.api}/api/PMP/GetAll`, // ?DStart=09/01/2019&DEnd=09/02/2019&PMPId={"PMPId":1110}
    },
  };

  // pressureMonitors = new BehaviorSubject<PressureMonitor[]>([]);
  constructor(private afs: AngularFirestore, private httpService: HttpService) {}

  getPressureMonitor(): Observable<PressureMonitor[]> {
    return this.afs
      .collection<any>('PressureMonitor')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            const { influenceArea, location, ...dataNoArrays } = data;
            return {
              uid: id,
              influenceArea: JSON.parse(influenceArea),
              location: JSON.parse(location),
              ...dataNoArrays,
            };
          })
        )
      );
  }

  savePressureMonitor(pm: PressureMonitor) {
    const { uid, influenceArea, location, ...pmNoArray } = pm;
    const entryUid = uid === '' ? pm.pmpId.toString() : pm.uid;

    return this.afs
      .collection<any>('PressureMonitor')
      .doc(entryUid)
      .set(
        {
          uid: entryUid,
          influenceArea: JSON.stringify(influenceArea),
          location: JSON.stringify(location),
          ...pmNoArray,
        },
        { merge: true }
      );
  }

  deletePressureMonitor(pm: PressureMonitor) {
    return this.afs.collection<any>('PressureMonitor').doc(pm.uid).delete();
  }

  getPumphouses(): Observable<PumpHouse[]> {
    // return this.afs.collection<PumpHouse>('PumpHouse').valueChanges();
    return this.afs
      .collection<any>('PumpHouse')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            const { influenceArea, location, ...dataNoArrays } = data;
            return {
              id,
              influenceArea: influenceArea ? JSON.parse(influenceArea) : [],
              location: location ? JSON.parse(location) : [],
              ...dataNoArrays,
            };
          })
        )
      );
  }

  savePumpHouse(ph: PumpHouse): PumpHouse {
    // const { influenceArea, location, ...pmNoArray } = pm;
    this.afs
      .collection<any>('PumpHouse')
      .doc(ph.id)
      .set(
        {
          influenceArea: JSON.stringify(ph.influenceArea),
          location: JSON.stringify(ph.location),
          description: ph.description,
          status: 'on',
          areasCovered: ph.areasCovered,
        },
        { merge: true }
      );

    return ph;
  }

  getCurrentPMPData(): Observable<APIResponse> {
    return this.httpService.get<APIResponse>(this.urls.PMP.current);
  }

  // Unserviceable Areas Related Functions
  getUnserviceableAreas(): Observable<UnserviceableArea[]> {
    return this.afs
      .collection<any>('UnserviceableAreas')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            const { influenceArea, name, areasCovered } = data;
            return {
              uid: id,
              influenceArea: influenceArea ? JSON.parse(influenceArea) : [],
              name,
              areasCovered,
            };
          })
        )
      );
  }

  saveUnserviceableArea(pm: UnserviceableArea) {
    const { influenceArea, areasCovered, name, uid } = pm;

    if (uid === '') {
      return this.afs.collection<any>('UnserviceableAreas').add({
        influenceArea: JSON.stringify(influenceArea),
        areasCovered,
        name,
      });
    }

    return this.afs
      .collection<any>('UnserviceableAreas')
      .doc(uid)
      .set(
        {
          influenceArea: JSON.stringify(influenceArea),
          areasCovered,
          name,
        },
        { merge: true }
      );
  }

  deleteUnserviceableArea(ua: UnserviceableArea) {
    return this.afs.collection<any>('UnserviceableAreas').doc(ua.uid).delete();
  }

  getPMPDataWithParameters(id: string, dStart: string, dEnd: string): Observable<APIResponse> {
    const urlWithParameter = `${this.urls.PMP.getall}?DStart=${dStart}&DEnd=${dEnd}&PMPId=${id}`;

    return this.httpService.get<APIResponse>(urlWithParameter);
  }

  getUsers(): Observable<User[]> {
    return this.afs
      .collection<any>('Users')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return {
              uid: id,
              email: data.email,
              isAdmin: data.isAdmin,
              isAllowed: data.isAllowed,
            };
          })
        )
      );
  }

  saveUser(user: User): User {
    // const { influenceArea, location, ...pmNoArray } = pm;
    this.afs
      .collection<any>('Users')
      .doc(user.uid)
      .set({ isAdmin: user.isAdmin, isAllowed: user.isAllowed }, { merge: true });

    return user;
  }

  getSettings(): Observable<ISettings> {
    return this.afs
      .collection<any>('Settings')
      .snapshotChanges()
      .pipe(
        map((actions: any) => {
          const settings = {};

          const response = actions.map((action) => {
            const data = action.payload.doc.data();
            const id = action.payload.doc.id;
            const { value } = data;

            settings[id] = value;
          });

          return settings as ISettings;
        })
      );
  }

  setSettings(refreshInterval, availableOptions): void {
    const currentDayAvailableOption = _.uniq(availableOptions);

    this.afs.collection<any>('Settings').doc('refreshInterval').update({ value: refreshInterval });
    this.afs.collection<any>('Settings').doc('currentDayAvailableOption').set({ value: currentDayAvailableOption });
  }

  // initializePMPData() {
  //   pmpData.forEach((pmd) => {
  //     const pmpToSave: PressureMonitor = {
  //       uid: pmd.pmpId.toString(),
  //       influenceArea: [],
  //       location: fromLonLat([pmd.lng, pmd.lat]),
  //       areasCovered: '',
  //       ...pmd
  //     };

  //     this.savePressureMonitor(pmpToSave);
  //   });
  // }
}
