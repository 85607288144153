import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@guards/auth.guard';
import { LoginComponent } from './modules/auth/containers/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '/view/pmp', pathMatch: 'full' },
  {
    path: 'view',
    loadChildren: () => import('./modules/view-map/view-map.module').then((mod) => mod.ViewMapModule),
    data: {
      title: 'Water Supply Monitoring - Views'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then((mod) => mod.AdminModule),
    data: {
      title: 'Water Supply Monitoring - Admin'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then((mod) => mod.ReportsModule),
    data: {
      title: 'Water Supply Monitoring - Reports'
    },
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
