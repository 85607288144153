import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { State } from '@state/index'; // Load the reducer
import { isLoggedIn, user } from '@state/reducers'; // Load the reducer

import { AuthService } from '../service/auth.service';
import { User } from '@models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isLoggedIn: Observable<boolean>;
  currentUser: Observable<User>;

  constructor(private router: Router, private store: Store<State>) {
    this.isLoggedIn = this.store.pipe(select(isLoggedIn));
    this.currentUser = this.store.pipe(select(user));
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isLoggedIn.pipe(
      tap((loggedIn) => {
        if (!loggedIn) {
          sessionStorage.setItem('redirectUrl', state.url);
          this.router.navigate(['/login']);
        } else {
          sessionStorage.removeItem('redirectUrl');

          if (next.routeConfig.path === 'admin') {
            this.currentUser.subscribe((data) => {
              if (!data.isAdmin) {
                this.router.navigate(['/view/pmp']);
              }
            }).unsubscribe();
          }
        }
      })
    );
  }

  // TODO: Add canLoad here, this will check if the current user is able to load the module based on their permission
}
