import { Input } from '@angular/core';

import { Component, OnInit, ContentChildren, QueryList, AfterContentInit, OnDestroy } from '@angular/core';
import { Map, View, Feature, Overlay } from 'ol';
import { Tile } from 'ol/layer'; // Heatmap as HeatmapLayer,
import { OSM, Vector as VectorSource } from 'ol/source';
import { Modify } from 'ol/interaction.js';
import { fromLonLat } from 'ol/proj';
import { Style, Fill, Stroke, Circle as CircleStyle } from 'ol/style'; // ,
import VectorLayer from 'ol/layer/Vector';

import { MarkersDirective } from '@app/core/directives/markers.directive';
import { PolygonsDirective } from '@app/core/directives/polygons.directive';
import { EditableMarkerDirective } from '@app/core/directives/editable-marker.directive';
import { EditablePolygonDirective } from '@app/core/directives/editable-polygon.directive';
import { MapPopupDirective } from '@app/core/directives/map-popup.directive';
import { Subscription } from 'rxjs';

import { MapComponent } from '@core/abstract/map-component.abstract';

@Component({
  selector: 'app-ol-map',
  templateUrl: './ol-map.component.html',
  styleUrls: ['./ol-map.component.scss'],
  queries: {
    markers: new ContentChildren(MarkersDirective),
    polygons: new ContentChildren(PolygonsDirective),
    editableMarker: new ContentChildren(EditableMarkerDirective),
    editablePolygon: new ContentChildren(EditablePolygonDirective),
    mapPopup: new ContentChildren(MapPopupDirective),
  },
})
export class OlMapComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() defaultIcon = 'assets/images/pressure.png';
  @Input() defaultRGB = '96, 95, 93';

  // map-related
  map: Map;
  displaySource: VectorSource; // this is the layer that displays saved data
  drawSource: VectorSource; // this is the temporary layer that contains the interactive geometry

  // @ContentChildren(MarkersDirective) markers: QueryList<MarkersDirective>;
  markers: QueryList<MarkersDirective>;
  polygons: QueryList<PolygonsDirective>;
  editableMarker: QueryList<EditableMarkerDirective>;
  editablePolygon: QueryList<EditablePolygonDirective>;
  mapPopup: QueryList<MapPopupDirective>;

  componentsWithClick: MapComponent[] = [];

  // subscribers
  subsStack: Subscription[] = [];

  // popup: Overlay = null;

  constructor() {}

  ngOnInit() {
    this.initMap();
  }

  ngAfterContentInit() {
    // this only triggers if there's a change
    this.subsStack.push(
      this.markers.changes.subscribe((mc) => {
        const mcs = mc.map((m) => m);
        this.deleteFromClickable(mcs);
        this.deleteFeatures('Point');
        this.createMarkers(mcs);
      })
    );

    this.subsStack.push(
      this.polygons.changes.subscribe((pc: PolygonsDirective[]) => {
        const pcs = pc.map((p) => p);
        this.deleteFromClickable(pcs);
        this.deleteFeatures('Polygon');
        this.createPolygons(pcs);
      })
    );

    this.subsStack.push(
      this.editableMarker.changes.subscribe((mc) => {
        const emcs = mc.map((m) => m);
        this.deleteFromClickable(emcs);
        this.deleteEditableFeatures('Point');
        this.createEditableMarker(emcs);
      })
    );

    this.subsStack.push(
      this.editablePolygon.changes.subscribe((pc) => {
        const epcs = pc.map((p) => p);
        this.deleteFromClickable(epcs);
        this.deleteEditableFeatures('Polygon');
        this.createEditablePolygon(epcs);
      })
    );

    this.subsStack.push(
      this.mapPopup.changes.subscribe((mps) => {
        this.deleteOverlays();
        this.createMapPopups(mps.map((mp) => mp));
      })
    );

    setTimeout(() => {
      this.map.updateSize();
    }, 2000);
  }

  ngOnDestroy() {
    if (this.subsStack && this.subsStack.length > 0) {
      this.subsStack.forEach((subs) => subs.unsubscribe);
    }
  }

  initMap() {
    // default center for Cabanatuan
    const mapLat = 15.4862;
    const mapLng = 120.9668;
    const defaultZoom = 13;

    this.displaySource = new VectorSource();
    this.drawSource = new VectorSource();

    const displayVector = new VectorLayer({
      source: this.displaySource,
      style: new Style({
        fill: new Fill({
          color: `rgba(${this.defaultRGB}, 0.7)`,
        }),
        stroke: new Stroke({
          color: `rgba(${this.defaultRGB}, 1)`,
          width: 1,
        }),
      }),
    });

    // TODO: Make the draw fill and the circle colors configurable
    const drawVector = new VectorLayer({
      source: this.drawSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.3)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
    });

    // initialize the map
    // at this point no data is plotted
    this.map = new Map({
      target: 'map',
      layers: [
        new Tile({
          source: new OSM(),
        }),
        displayVector,
        drawVector,
      ],
      view: new View({
        center: fromLonLat([mapLng, mapLat]),
        zoom: defaultZoom,
        minZoom: 12,
        maxZoom: 17
      }),
    });

    const modify = new Modify({ source: this.drawSource });
    this.map.addInteraction(modify);
    // this.popup = new Overlay({
    //   element: document.getElementById('testPop')
    // });

    // this.map.addOverlay(this.popup);
    // this.popup.setPosition([13467456.360937465, 1748552.856883912]);

    this.onClickHandler();
  }

  createMarkers(markers: MarkersDirective[]) {
    markers.forEach((m: MarkersDirective) => {
      m.render(this.displaySource, this.defaultIcon);

      if (m.hasClickListener) {
        this.componentsWithClick.push(m);
      }
    });
  }

  createPolygons(polygons: PolygonsDirective[]) {
    polygons.forEach((p: PolygonsDirective, idx: number) => {
      p.render(this.displaySource);

      if (p.hasClickListener) {
        this.componentsWithClick.push(p);
      }
    });
  }

  createEditableMarker(markers: EditableMarkerDirective[]) {
    // there should only be one of these editable markers
    markers.forEach((m: EditableMarkerDirective) => {
      m.render(this.drawSource, this.map);
    });
  }

  createEditablePolygon(polygons: EditablePolygonDirective[]) {
    // there should only be one of these editable markers
    polygons.forEach((p: EditablePolygonDirective) => {
      p.render(this.drawSource, this.map);
    });
  }

  createMapPopups(popups: MapPopupDirective[]) {
    popups.forEach((p: MapPopupDirective) => {
      p.render(this.map);
    });
  }

  deleteFeatures(featureType: 'Point' | 'Polygon') {
    // console.log(this.componentsWithClick);
    this.displaySource.forEachFeature((f) => {
      if (f.getGeometry().getType() === featureType) {
        this.displaySource.removeFeature(f);
      }
    });
  }

  deleteEditableFeatures(featureType: 'Point' | 'Polygon') {
    this.drawSource.forEachFeature((f) => {
      if (f.getGeometry().getType() === featureType) {
        this.drawSource.removeFeature(f);
      }
    });
  }

  deleteFromClickable(
    comps: PolygonsDirective[] | MarkersDirective[] | EditableMarkerDirective[] | EditablePolygonDirective[]
  ) {
    comps.forEach((comp) => {
      const clickableIdx = this.findInClickables(comp.id);
      if (clickableIdx >= 0) {
        this.componentsWithClick.splice(clickableIdx, 1);
      }
    });
  }

  findInClickables(id: string) {
    return this.componentsWithClick.findIndex((clk) => clk.id === id);
  }

  deleteOverlays() {
    this.map.getOverlays().forEach((o) => this.map.removeOverlay(o));
  }

  onClickHandler() {
    this.map.on('click', (evt) => {
      const feature = this.map.forEachFeatureAtPixel(evt.pixel, (ft, layer) => {
        return ft;
      });

      if (feature) {
        // loop through features that are subscribed
        this.componentsWithClick.forEach((mc) => {
          if (mc.featureId === feature.getId()) {
            mc.onClick(evt.coordinate);
          }
        });
      } else {
        // send an event to any popup that there was no feature clicked
        this.mapPopup.forEach((mp) => mp.hide());
      }
    });
  }
}
