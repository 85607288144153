import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of, from, forkJoin } from 'rxjs';
import { catchError, map, switchMap, mergeMap, tap } from 'rxjs/operators';
import { User as FBUser } from 'firebase/app';

import { AuthService } from '@services';
import {
  AuthActionTypes,
  CheckLogin,
  Unauthenticated,
  LoginSuccess,
  LoginFailure,
  LogoutCancelled,
  LogoutConfirmed
} from '../actions';
import { State } from '@app/state';

/**
 * Pattern Flow:
 * 1. Actions are dispatched
 * 2. Effects route the action to its respective service
 * 3. Reducers consume the result of that
 */

@Injectable()
export class AuthEffects {
  constructor(private store: Store<State>, private authService: AuthService, private actions: Actions) {}

  /**
   * Check if the user is still logged into the app
   * This will also be responsible for filling in the user profile data in the state
   */
  @Effect()
  checkLogin: Observable<Action> = this.actions.pipe(
    ofType(AuthActionTypes.CheckLogin),
    map((action: CheckLogin) => action.payload),
    switchMap((payload) => this.authService.afAuthObj.authState), // subscribing to the firebase authState object
    switchMap((authData) => {
      // returns user data if logged in
      if (authData) {
        // Get the permissions fromt he firebase User node
        return this.authService.getUserPermissions(authData.uid).pipe(
          map((u) => {
            // merge the other data from the authData object with the data from firebase
            u.photoURL = authData.photoURL;
            u.displayName = authData.displayName;
            return u;
          }),
          catchError((err) => {
            return of('No permission error');
          })
        );
      } else {
        return of(null);
      }
    }),
    map((user) => {
      if (user) {
        // catch the result of the get permission
        // this will be true if firebase rejects the read on the User node
        // There is a firebase rule on the DB that if the authenticated user doesn't have the isAllowed flag
        // Firebase will reject the request
        if (user === 'No permission error') {
          return new LoginFailure(user);
        } else {
          return new LoginSuccess(user);
        }
      } else {
        return new Unauthenticated();
      }
    }),
    catchError((err) => of(new LoginFailure(err.message)))
  );

  @Effect()
  login: Observable<Action> = this.actions.pipe(
    ofType(AuthActionTypes.Login),
    switchMap(() => {
      return from(this.authService.googleSignin());
    }),
    map((credential) => {
      return new CheckLogin();
    }),
    catchError((err) => of(new LoginFailure(err.message)))
  );

  // need to fix this
  @Effect()
  logout: Observable<Action> = this.actions.pipe(
    ofType(AuthActionTypes.Logout),
    switchMap(() => {
      return from(this.authService.signOut());
    }),
    map((authData) => {
      return new LogoutConfirmed();
    }),
    catchError((err) => of(new LogoutCancelled()))
  );
}
