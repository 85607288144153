import { Action } from '@ngrx/store';
import { HiddenFlagParam, UnserviceableArea } from '@models';

export enum UAActionTypes {
  SetUAVisibility = '[UA] Set an Unserviceable Areas visibility',
  SetUAVisibilityDone = '[UA] Set an Unserviceable Areas visibility done',
  SaveUA = '[UA] Save an Unserviceable Area to DB',
  SaveUASuccess = '[UA] Save an Unserviceable Area to DB successfully',
  SaveUAFail = '[UA] Save an Unserviceable Area to DB failed',
  DeleteUA = '[UA] Delete an Unserviceable Area',
  DeleteUASuccess = '[UA] Delete an Unserviceable Area successfully',
  DeleteUAFail = '[UA] Delete an Unserviceable Area failed',
  GetUnserviceableAreas = '[UA] Get Unserviceable Areas',
  GetWithUnserviceableAreasSuccess = '[UA] Get Unserviceable Areas Success',
  GetWithUnserviceableAreasFail = '[UA] Get Unserviceable Areas Fail'
}

export class SetUAVisibility implements Action {
  readonly type = UAActionTypes.SetUAVisibility;

  constructor(public payload: HiddenFlagParam) {}
}
export class SetUAVisibilityDone implements Action {
  readonly type = UAActionTypes.SetUAVisibilityDone;

  constructor(public payload: UnserviceableArea[]) {}
}
export class SaveUA implements Action {
  readonly type = UAActionTypes.SaveUA;

  constructor(public payload: UnserviceableArea) {}
}
export class SaveUASuccess implements Action {
  readonly type = UAActionTypes.SaveUASuccess;

  constructor(public payload: UnserviceableArea) {}
}
export class SaveUAFail implements Action {
  readonly type = UAActionTypes.SaveUAFail;

  constructor(public payload: string) {}
}
export class DeleteUA implements Action {
  readonly type = UAActionTypes.DeleteUA;

  constructor(public payload: UnserviceableArea) {}
}
export class DeleteUASuccess implements Action {
  readonly type = UAActionTypes.DeleteUASuccess;

  constructor() {}
}
export class DeleteUAFail implements Action {
  readonly type = UAActionTypes.DeleteUAFail;

  constructor(public payload: string) {}
}
export class GetUnserviceableAreas implements Action {
  readonly type = UAActionTypes.GetUnserviceableAreas;

  constructor() { };
}
export class GetWithUnserviceableAreasSuccess implements Action {
  readonly type = UAActionTypes.GetWithUnserviceableAreasSuccess;

  constructor(public unserviceableAreas: UnserviceableArea[]) { };
}
export class GetWithUnserviceableAreasFail implements Action {
  readonly type = UAActionTypes.GetWithUnserviceableAreasFail;

  constructor(public payload: string) { };
}

export type UAActions =
  | SetUAVisibility
  | SetUAVisibilityDone
  | SaveUA
  | SaveUASuccess
  | SaveUAFail
  | DeleteUA
  | DeleteUASuccess
  | DeleteUAFail
  | GetUnserviceableAreas
  | GetWithUnserviceableAreasSuccess
  | GetWithUnserviceableAreasFail;