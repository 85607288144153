import { Component, OnInit, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-alert-bar',
  template: ''
})
export class AlertBarComponent implements OnInit {
  @Input() message = '';
  @Input() action = '';
  @Input() duration = 2000;
  @Input() panelClass: '';
  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.openSnackBar(this.message, this.action);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: this.duration,
      panelClass: [this.panelClass]
    });
  }
}
