import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';

import { DataService } from '@services';
import {
  PMPDataActionTypes,
  GetCurrentDataSuccess,
  GetCurrentDataFail,
  SetAllPMPVisibility,
  SetAllPMPVisibilitySuccess,
  GetDataByParameter,
  GetHourlyView,
} from '../actions';
import { State } from '@app/state';
import { APIResponse, PressureMonitor, PMPDataResult } from '@models';
import moment from 'moment';
import { getPMPData } from '../reducers';
import _ from 'lodash';
import { sliderOptions } from '../../modules/view-map/helpers';

/**
 * Pattern Flow:
 * 1. Actions are dispatched
 * 2. Effects route the action to its respective service
 * 3. Reducers consume the result of that
 */

@Injectable()
export class PMPDataEffects {
  constructor(private store: Store<State>, private dataService: DataService, private actions: Actions) {}

  // Effects automatically unsubscribe
  @Effect()
  getCurrentPMPData: Observable<Action> = this.actions.pipe(
    ofType(PMPDataActionTypes.GetCurrentData),
    mergeMap(() => combineLatest(
      this.dataService.getCurrentPMPData(),
      this.dataService.getSettings()
    )),
    mergeMap((response) => {
      const [apiData, settingsData] = response;
      const { currentDayAvailableOption } = settingsData;
      const selectedTime = currentDayAvailableOption.sort((a, b) => b - a);
      const today = new Date();
      const formattedDate = moment(today).format('MM/DD/YYYY').toString();
      const currentTime = today.getHours();
      const currentTimestop = selectedTime.find((time) => time <= currentTime);
      const date = moment(new Date()).format('MM/DD/YYYY').toString();
      const timeString = sliderOptions[currentTimestop].timeValue;
      const timeParams = timeString.split('-');
      const startTime = `${formattedDate} ${timeParams[0]}`;
      const endTime = `${formattedDate} ${timeParams[1]}`;
      
      const { result } = apiData;
      const pmpIds = result.map((item) => item.pmpId);
      const pmpIdParam = pmpIds.map((id) => {
        return { PMPId: id };
      });

      

      return combineLatest(
        this.dataService.getPressureMonitor(),
        this.dataService.getPMPDataWithParameters(JSON.stringify(pmpIdParam), startTime, endTime)
      );
    }),
    map((response) => {
      const [pmpList, pmpDataRes] = response;
      const date = moment(new Date()).format('MM/DD/YYYY').toString();

      if (pmpDataRes.success) {
        pmpList.forEach((pmp: PressureMonitor) => {
          // This will extract the pressure for each computation parameter.
          const computationBasis = pmp.computation
            ? pmp.computation.map((pmpID) => {
                if (typeof pmpID === 'object') {
                  const groupedID = Object.keys(pmpID).map((key) => pmpID[key]) as number[];
                  const pmps = pmpDataRes.result.filter((pd) => groupedID.includes(pd.pmpId));
                  const pressurePerPMP = pmps.map((pmpData) => pmpData.pressure);
                  // This will compute for the average based on the grouped parameter

                  const averagePressure =
                    pressurePerPMP.length > 0
                      ? pressurePerPMP.reduce((total, num) => total + num) / pressurePerPMP.length
                      : -1;

                  return averagePressure;
                }

                const matchedPMP = pmpDataRes.result.find((pd) => pd.pmpId === pmpID);

                return matchedPMP ? matchedPMP.pressure : -1;
              })
            : [];

          const computedPressure =
            computationBasis.length > 0
              ? computationBasis.reduce((total, num) => total + num) / computationBasis.length
              : -1;

          pmp.currentData = computedPressure
            ? {
                pressure: computedPressure,
                dateMeasured: date,
              }
            : undefined;
        });

        return new GetCurrentDataSuccess(pmpList);
      } else {
        return new GetCurrentDataFail(pmpDataRes.errors);
      }
    }),
    catchError((err) => of(new GetCurrentDataFail(err.message)))
  );

  @Effect()
  getDataByParameter: Observable<Action> = this.actions.pipe(
    ofType(PMPDataActionTypes.GetDataByParameter),
    map((action: GetDataByParameter) => action),
    withLatestFrom(this.store.pipe(select(getPMPData))),
    mergeMap((data) => {
      const [parameters, pmpData] = data;
      const { id, dStart, dEnd } = parameters;
      const idParams = id;
      let pmpIDs;

      if (id.includes('all')) {
        pmpIDs = pmpData.map((item) => {
          return { PMPId: item.uid };
        });
      } else {
        const pmps = pmpData.filter((pmp) => id.includes(pmp.uid));
        const computationBasis = pmps.map((pmp) => {
          const { computation } = pmp;

          return computation.map((compute) => {
            if (typeof compute === 'object') {
              return Object.keys(compute).map((key) => compute[key] as number);
            }

            return compute;
          });
        });

        const uniquePMP = _.uniq(_.flattenDeep(computationBasis));

        pmpIDs = uniquePMP.map((item) => {
          return { PMPId: item };
        });
      }

      return combineLatest(
        this.dataService.getPressureMonitor(),
        this.dataService.getPMPDataWithParameters(JSON.stringify(pmpIDs), dStart, dEnd),
        of(idParams)
      );
    }),
    map((response) => {
      const [pmpList, pmpDataRes, selectedPMPs] = response;

      if (pmpDataRes.success) {
        pmpList.forEach((pmp: PressureMonitor) => {
          // find the current data for this pmp
          const computationBasis = pmp.computation
            ? pmp.computation.map((pmpID) => {
                if (typeof pmpID === 'object') {
                  const groupedID = Object.keys(pmpID).map((key) => pmpID[key]) as number[];
                  const pmps = pmpDataRes.result.filter((pd) => groupedID.includes(pd.pmpId));
                  const pressurePerPMP = pmps.map((pmpData) => pmpData.pressure);
                  // This will compute for the average based on the grouped parameter
                  const averagePressure =
                    pressurePerPMP.length > 0
                      ? pressurePerPMP.reduce((total, num) => total + num) / pressurePerPMP.length
                      : -1;

                  return averagePressure;
                }

                const matchedPMP = pmpDataRes.result.find((pd) => pd.pmpId === pmpID);

                return matchedPMP ? matchedPMP.pressure : -1;
              })
            : [];

          const sumOfPressures =
            computationBasis.length > 0 ? computationBasis.reduce((total, num) => total + num) : -1;
          const computationBasisLength = computationBasis ? computationBasis.length : 1;
          const averagePressure = Number((sumOfPressures / computationBasisLength).toFixed(2));

          const computationPMPids = pmp.computation ? _.flattenDeep(pmp.computation) : [];
          const dataByParams = pmpDataRes.result.filter((pd) => computationPMPids.includes(pd.pmpId));
          const datesFromData = _.uniq(dataByParams.map((item) => item.dDate));

          const graphData = datesFromData.map((date: string) => {
            const data = dataByParams.filter((item: PMPDataResult) => item.dDate === date);
            const dateObj = moment(date);

            const computationBasis = pmp.computation
              ? pmp.computation.map((pmpID) => {
                  if (typeof pmpID === 'object') {
                    const groupedID = Object.keys(pmpID).map((key) => pmpID[key]) as number[];
                    const pmps = dataByParams.filter((pd) => groupedID.includes(pd.pmpId));
                    const pressurePerPMP = pmps.length > 0 ? pmps.map((pmpData) => pmpData.pressure) : [0];
                    const averagePressure =
                      pressurePerPMP.length > 0
                        ? pressurePerPMP.reduce((total, num) => total + num) / pressurePerPMP.length
                        : 0;

                    return averagePressure;
                  }

                  const matchedPMP = dataByParams.find((pd) => pd.pmpId === pmpID);

                  return matchedPMP ? matchedPMP.pressure : 0;
                })
              : [];

            const sumOfPressures = computationBasis ? computationBasis.reduce((total, num) => total + num) : 0;
            const computationBasisLength = computationBasis ? computationBasis.length : 1;
            const averagePressure = Number((sumOfPressures / computationBasisLength).toFixed(2));

            return {
              pressure: averagePressure,
              month: dateObj.format('MM'),
              year: dateObj.format('YYYY'),
              day: dateObj.format('DD'),
              week: dateObj.format('WW'),
              hour: dateObj.format('HH'),
            };
          });

          pmp.currentData = {
            pressure: averagePressure,
            dateMeasured: '',
            hits: graphData.length,
            sumOfPressures: sumOfPressures,
            averagePressure: averagePressure,
            graphData: graphData,
          };

          if (selectedPMPs.includes('all') || selectedPMPs.includes(pmp.uid)) {
            pmp.isHidden = false;
          } else {
            pmp.isHidden = true;
          }
        });
        return new GetCurrentDataSuccess(pmpList);
      } else {
        return new GetCurrentDataFail(pmpDataRes.errors);
      }
    })
  );

  @Effect()
  SetAllPMPVisibility: Observable<Action> = this.actions.pipe(
    ofType(PMPDataActionTypes.SetAllPMPVisibility),
    map((action: SetAllPMPVisibility) => action.state),
    withLatestFrom(this.store.pipe(select(getPMPData))),
    map((actionAndStoreState) => {
      const [state, pmps] = actionAndStoreState;
      const newPMPs = pmps.map((p) => {
        const newPMP = { ...p };

        newPMP.isHidden = !state;

        return newPMP;
      });

      return new SetAllPMPVisibilitySuccess(newPMPs);
    })
  );
}

/**
 * TODO: Add effect here to fetch PMP with data and unserviceable areas
 */
