import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';

import { DataService } from '@services';
import {
  PMPActionTypes,
  GetPMPFail,
  GetPMPSuccess,
  SetPMPVisibility,
  SetPMPVisibilityDone,
  SavePMP,
  SavePMPSuccess,
  SavePMPFail,
  DeletePMP,
  DeletePMPSuccess,
  DeletePMPFail,
  SetSelectedPMPSuccess,
  SetSelectedPMPFail,
} from '../actions';
import { State } from '@app/state';
import { PressureMonitor } from '@app/core/models';
import { getPMP } from '../reducers';

/**
 * Pattern Flow:
 * 1. Actions are dispatched
 * 2. Effects route the action to its respective service
 * 3. Reducers consume the result of that
 */

@Injectable()
export class PMPEffects {
  constructor(private store: Store<State>, private dataService: DataService, private actions: Actions) {}

  // Effects automatically unsubscribe
  @Effect()
  getPMP: Observable<Action> = this.actions.pipe(
    ofType(PMPActionTypes.GetPMP),
    mergeMap(() =>
      this.dataService.getPressureMonitor().pipe(
        map((pmps) => new GetPMPSuccess(pmps)),
        catchError((err) => of(new GetPMPFail(err.message)))
      )
    )
  );

  @Effect()
  SetPMPVisibility: Observable<Action> = this.actions.pipe(
    ofType(PMPActionTypes.SetPMPVisibility),
    map((action: SetPMPVisibility) => action.payload),
    withLatestFrom(this.store.pipe(select(getPMP))),
    map((actionAndStoreState) => {
      const newPMPs = actionAndStoreState[1].map((p) => {
        const newPMP = { ...p };
        if (newPMP.uid === actionAndStoreState[0].id) {
          newPMP.isHidden = actionAndStoreState[0].isHidden;
        }
        return newPMP;
      });

      return new SetPMPVisibilityDone(newPMPs);
    })
  );

  @Effect()
  SavePMP: Observable<Action> = this.actions.pipe(
    ofType(PMPActionTypes.SavePMP),
    map((action: SavePMP) => action.payload),
    mergeMap(async (pmpToSave) => {
      await this.dataService.savePressureMonitor(pmpToSave);
      const newPMP = { ...pmpToSave };
      newPMP.uid = pmpToSave.pmpId.toString();
      return pmpToSave;
      // .then(savedPMP => {
      //   // firebase set returns a null, so if it's empty it means we just updated
      //   return savedPMP;
      // });
    }),
    map((pmp) => new SavePMPSuccess(pmp)),
    catchError((err) => of(new SavePMPFail(err.message)))
  );

  @Effect()
  DeletePMP: Observable<Action> = this.actions.pipe(
    ofType(PMPActionTypes.DeletePMP),
    map((action: DeletePMP) => action.payload),
    mergeMap(async (pmpToDelete) => {
      await this.dataService.deletePressureMonitor(pmpToDelete);
      return true;
    }),
    map(() => new DeletePMPSuccess()),
    catchError((err) => of(new DeletePMPFail(err.message)))
  );

  @Effect()
  SetSelectedPMPData: Observable<Action> = this.actions.pipe(
    ofType(PMPActionTypes.SetSelectedPMP),
    map((payload: SetSelectedPMPSuccess) => {
      
      return new SetSelectedPMPSuccess(payload.payload)
    }),
    catchError((err) => of(new SetSelectedPMPFail(err)))
  );
}
