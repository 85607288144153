import { Action } from '@ngrx/store';
import { PressureMonitor, HiddenFlagParam, UnserviceableArea } from '@models';

export enum PMPActionTypes {
  GetPMP = '[PMP] Get all Pressure Monitors',
  GetPMPSuccess = '[PMP] Get all Pressure Monitors successfully',
  GetPMPFail = '[PMP] Get all Pressure Monitors Fail',
  SetPMPVisibility = '[PMP] Set a Pressure Monitors visibility',
  SetPMPVisibilityDone = '[PMP] Set a Pressure Monitors visibility done',
  SavePMP = '[PMP] Save a Pressure Monitor to DB',
  SavePMPSuccess = '[PMP] Save a Pressure Monitor to DB successfully',
  SavePMPFail = '[PMP] Save a Pressure Monitor to DB failed',
  DeletePMP = '[PMP] Delete a Pressure Monitor',
  DeletePMPSuccess = '[PMP] Delete a Pressure Monitor successfully',
  DeletePMPFail = '[PMP] Delete a Pressure Monitor failed',
  SetSelectedPMP = '[PMP] Set Selected PMP',
  SetSelectedPMPSuccess = '[PMP] Set Selected PMP Success',
  SetSelectedPMPFail = '[PMP] Set Selected PMP Fail',
  GetPMPWithUnserviceables = '[PMP] Get Pressure Monitors with Unserviceable Areas',
  GetPMPWithUnserviceablesSuccess = '[PMP] Get Pressure Monitors with Unserviceable Areas Success',
  GetPMPWithUnserviceablesFail = '[PMP] Get Pressure Monitors with Unserviceable Areas Fail',
  ClearSelectedPMP = '[PMP] Clear Selected PMP'
}
// TODO: Add actions for selecting PMPs

export class GetPMP implements Action {
  readonly type = PMPActionTypes.GetPMP;

  constructor() {}
}

export class GetPMPSuccess implements Action {
  readonly type = PMPActionTypes.GetPMPSuccess;

  constructor(public payload: PressureMonitor[]) {}
}

export class GetPMPFail implements Action {
  readonly type = PMPActionTypes.GetPMPFail;

  // payload is the error message
  constructor(public payload: string) {}
}

export class SetPMPVisibility implements Action {
  readonly type = PMPActionTypes.SetPMPVisibility;

  constructor(public payload: HiddenFlagParam) {}
}

export class SetPMPVisibilityDone implements Action {
  readonly type = PMPActionTypes.SetPMPVisibilityDone;

  // will return a new set of PMPs with the edited true or false state
  constructor(public payload: PressureMonitor[]) {}
}

export class SavePMP implements Action {
  readonly type = PMPActionTypes.SavePMP;

  constructor(public payload: PressureMonitor) {}
}

export class SavePMPSuccess implements Action {
  readonly type = PMPActionTypes.SavePMPSuccess;

  constructor(public payload: PressureMonitor) {}
}

export class SavePMPFail implements Action {
  readonly type = PMPActionTypes.SavePMPFail;

  // payload is the error message
  constructor(public payload: string) {}
}

export class DeletePMP implements Action {
  readonly type = PMPActionTypes.DeletePMP;

  constructor(public payload: PressureMonitor) {}
}

export class DeletePMPSuccess implements Action {
  readonly type = PMPActionTypes.DeletePMPSuccess;

  constructor() {}
}

export class DeletePMPFail implements Action {
  readonly type = PMPActionTypes.DeletePMPFail;

  // payload is the error message
  constructor(public payload: string) {}
}

export class SetSelectedPMP implements Action {
  readonly type = PMPActionTypes.SetSelectedPMP;

  constructor(public payload: PressureMonitor|{}) { };
}

export class SetSelectedPMPSuccess implements Action {
  readonly type = PMPActionTypes.SetSelectedPMPSuccess;

  constructor(public payload: PressureMonitor) { };
}

export class SetSelectedPMPFail implements Action {
  readonly type = PMPActionTypes.SetSelectedPMPFail;

  constructor(public payload: string) { };
}

export class GetPMPWithUnserviceables implements Action {
  readonly type = PMPActionTypes.GetPMPWithUnserviceables;

  constructor() { };
}

export class GetPMPWithUnserviceablesSuccess implements Action {
  readonly type = PMPActionTypes.GetPMPWithUnserviceablesSuccess;

  constructor(public pmps: PressureMonitor[], public unserviceableAreas: UnserviceableArea[]) { };
}

export class GetPMPWithUnserviceablesFail implements Action {
  readonly type = PMPActionTypes.GetPMPWithUnserviceablesFail;

  constructor(public payload: string) { };
}

export type PMPActions =
  | GetPMP
  | GetPMPSuccess
  | GetPMPFail
  | SetPMPVisibility
  | SetPMPVisibilityDone
  | SavePMP
  | SavePMPSuccess
  | SavePMPFail
  | DeletePMP
  | DeletePMPSuccess
  | DeletePMPFail
  | SetSelectedPMP
  | SetSelectedPMPSuccess
  | SetSelectedPMPFail
  | GetPMPWithUnserviceables
  | GetPMPWithUnserviceablesSuccess
  | GetPMPWithUnserviceablesFail;
