import { Action } from '@ngrx/store';
import { User } from '@models';

export enum UserActionTypes {
  GetUsers = '[Users] Get Users',
  GetUsersSuccess = '[Users] Get Users Success',
  GetUsersFail = '[Users] Get Users Fail',
  UpdateUser = '[Users] Update User',
  UpdateUserSuccess = '[Users] Update User Success',
  UpdateUserFail = '[Users] Update User Fail'
};

export class GetUsers implements Action {
  readonly type = UserActionTypes.GetUsers;
}

export class GetUsersSuccess implements Action {
  readonly type = UserActionTypes.GetUsersSuccess;

  constructor (public users: User[]) { };
}

export class GetUsersFail implements Action {
  readonly type = UserActionTypes.GetUsersFail;

  constructor (public payload: string) { };
}

export class UpdateUser implements Action {
  readonly type = UserActionTypes.UpdateUser;

  constructor (public user: User) { };
}

export class UpdateUserSuccess implements Action {
  readonly type = UserActionTypes.UpdateUserSuccess;

  constructor (public users: User[]) { };
}

export class UpdateUserFail implements Action {
  readonly type = UserActionTypes.UpdateUserFail;

  constructor (public payload: string) { };
}

export type UserActions = 
  | GetUsers
  | GetUsersSuccess
  | GetUsersFail
  | UpdateUser
  | UpdateUserSuccess
  | UpdateUserFail;
