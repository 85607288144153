import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from '@state/index'; // Load the reducer
import { isLoggedIn } from '@state/reducers'; // Load the reducer
import * as actions from '@state/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pumpHouseMonitor';
  isLoggedIn: Observable<boolean>;
  isDrawerOpened = false;

  @ViewChild('drawer', { static: false })
  drawer: MatSidenav;

  constructor(private store: Store<State>) {}

  ngOnInit(): void {
    this.store.dispatch(new actions.CheckLogin());

    this.isLoggedIn = this.store.pipe(select(isLoggedIn));
  }

  onDrawerOpenChange() {
    this.isDrawerOpened = !this.isDrawerOpened;
  }
}
