import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';

import { DataService } from '@services';
import {
  UAActionTypes,
  SaveUA,
  SaveUASuccess,
  SaveUAFail,
  SetUAVisibility,
  SetUAVisibilityDone,
  GetWithUnserviceableAreasSuccess,
  GetWithUnserviceableAreasFail,
  DeleteUA,
  DeletePMPSuccess,
  DeleteUAFail,
  DeleteUASuccess
} from '../actions';
import { State } from '@app/state';
import { UnserviceableArea } from '@app/core/models';
import { getUnserviceableAreas } from '../reducers';

/**
 * Pattern Flow:
 * 1. Actions are dispatched
 * 2. Effects route the action to its respective service
 * 3. Reducers consume the result of that
 */

@Injectable()
export class UnserviceableAreasEffects {
  constructor(private store: Store<State>, private dataService: DataService, private actions: Actions) {}

  @Effect()
  SaveUA: Observable<Action> = this.actions.pipe(
    ofType(UAActionTypes.SaveUA),
    map((action: SaveUA) => action.payload),
    mergeMap(async (area: UnserviceableArea) => {
      await this.dataService.saveUnserviceableArea(area);

      return area;
    }),
    map((pmp) => new SaveUASuccess(pmp)),
    catchError((err) => of(new SaveUAFail(err.message)))
  );

  @Effect()
  getUnserviceableAreasData: Observable<Action> = this.actions.pipe(
    ofType(UAActionTypes.GetUnserviceableAreas),
    mergeMap(() => 
      combineLatest(
        this.dataService.getUnserviceableAreas()
      )
    ),
    map((response) => {
      const [areas] = response;

      return new GetWithUnserviceableAreasSuccess(areas);
    }),
    catchError((err) => of(new GetWithUnserviceableAreasFail(err)))
  );

  @Effect()
  SetAreaVisibility: Observable<Action> = this.actions.pipe(
    ofType(UAActionTypes.SetUAVisibility),
    map((action: SetUAVisibility) => action.payload),
    withLatestFrom(this.store.pipe(select(getUnserviceableAreas))),
    map((actionAndStoreState) => { console.log(actionAndStoreState);
      const newAreas = actionAndStoreState[1].map((p) => {
        const newArea = { ...p };
        console.log(newArea);
        if (newArea.uid === actionAndStoreState[0].id) {
          newArea.isHidden = actionAndStoreState[0].isHidden;
        }
        return newArea;
      });
      console.log(newAreas)
      return new SetUAVisibilityDone(newAreas);
    })
  );

  @Effect()
  DeleteArea: Observable<Action> = this.actions.pipe(
    ofType(UAActionTypes.DeleteUA),
    map((action: DeleteUA) => action.payload),
    mergeMap(async (areaToDelete) => {
      console.log(areaToDelete)
      await this.dataService.deleteUnserviceableArea(areaToDelete);
      return true;
    }),
    map(() => new DeleteUASuccess()),
    catchError((err) => of(new DeleteUAFail(err.message)))
  );
}
