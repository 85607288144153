import { Directive, EventEmitter, Output, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Feature, Map } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Point, Geometry } from 'ol/geom';
import { Draw, Snap } from 'ol/interaction.js';

import { uniqueID } from '@core/helper';
import { CoordinateChanges } from '@models';
import { Style, Fill, Circle as CircleStyle, Icon } from 'ol/style';

const idPrefix = 'editable_marker_';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[app-editable-marker], app-editable-marker'
})
export class EditableMarkerDirective implements OnChanges, OnDestroy {
  @Input() id: string = uniqueID();
  @Input() point: number[] | null = null;
  @Input() additionalData: any = null;
  @Input() isStaged: false;

  @Output() positionChanged = new EventEmitter<CoordinateChanges>();

  map: Map = null;
  parentVector: VectorSource = null;
  feature: Feature = null;
  snap: Snap;
  draw: Draw;

  constructor() {}

  get featureId(): string {
    return `${idPrefix}_${this.id}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach((k) => {
      if (!changes[k].firstChange) {
        // react only to recent changes
        switch (k) {
          case 'point':
            // if the point ir markerIcon was changed, we need to redraw this
            this.moveLocation();
            break;
          case 'additionalData':
            this.changeMetaData();
            break;
          case 'isStaged':
            this.setStagedStatus();
            break;
          default:
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.removeMapInteractions();
  }

  render(vectorSource: VectorSource, map: Map) {
    this.map = map;
    this.parentVector = vectorSource;

    if (this.point) {
      const point = new Point(this.point);
      // TODO: Add configurable styling here, if possible

      this.feature = new Feature({
        geometry: point,
        type: 'icon',
        ...this.additionalData
      });

      this.setStageStyle();

      this.feature.setId(this.featureId);

      this.parentVector.addFeature(this.feature);

      this.addGeometryOnChange(this.feature.getGeometry());
    }

    if (!this.isStaged) {
      this.addInteractions();
    }
  }

  moveLocation() {
    // removing it first from the map and redrawing it
    if (this.parentVector && this.feature) {
      this.parentVector.removeFeature(this.feature);
      this.render(this.parentVector, this.map);
    }
  }

  setStageStyle() {
    let style;
    if (this.isStaged) {
      style = new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: 'fraction' as any,
          anchorYUnits: 'fraction' as any,
          src: 'assets/images/pressure-red.png',
          scale: 0.5,
          opacity: 1
        })
      });
    } else {
      style = new Style({
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33'
          })
        })
      });
    }
    this.feature.setStyle(style);
  }

  changeMetaData() {
    if (this.feature) {
      this.feature.setProperties(this.additionalData);
    }
  }

  addInteractions() {
    // this is so we can attach event handlers
    this.draw = new Draw({
      source: this.parentVector,
      type: 'Point' as any
    });

    this.map.addInteraction(this.draw);
    this.snap = new Snap({ source: this.parentVector });
    this.map.addInteraction(this.snap);

    this.draw.on('drawstart', (e) => {
      // prevent multiple points and polygons
      if (this.feature) {
        this.parentVector.removeFeature(this.feature); // .getFeatures().forEach((f) => this.drawSource.removeFeature(f));
      }
    });
    this.draw.on('drawend', (e) => {
      if (!this.feature) {
        // if there was no feature before
        // we add the geometry change handler
        this.addGeometryOnChange(e.feature.getGeometry());
      }

      this.feature = e.feature;
      const coordinates = (this.feature.getGeometry() as Point).getCoordinates();
      this.positionChanged.emit({ id: this.id, coordinates });
    });
  }

  addGeometryOnChange(geometry: Geometry) {
    geometry.on('change', (evt) => {
      this.positionChanged.emit({ id: this.id, coordinates: (geometry as Point).getCoordinates() });
    });
  }

  removeMapInteractions() {
    if (this.map) {
      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.snap);
    }
  }

  setStagedStatus() {
    // if this polygon is "staged", remove all the interaction to it
    if (this.isStaged) {
      this.removeMapInteractions();
    } else {
      this.addInteractions();
    }

    this.setStageStyle();
  }
}
