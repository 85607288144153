import { UnserviceableArea } from '@models';
import { UAActions, UAActionTypes } from '../actions/unserviceableAreas.action';

export const uaFeatureKey = 'unserviceableArea';

export interface State {
  selectedUA: UnserviceableArea | null;
  unserviceableAreas: UnserviceableArea[] | null;
  errorMessage: string;
  successMessage: string;
  isSaving: boolean;
  isDeleting: boolean;
  isFiltering: boolean;
  isLoading: boolean;
}

export const initialState: State = {
  selectedUA:  null,
  unserviceableAreas: null,
  errorMessage: '',
  successMessage: '',
  isSaving: false,
  isDeleting: false,
  isFiltering: false,
  isLoading: false
};

export function reducer(state = initialState, action: UAActions): State {
  switch (action.type) {
    case UAActionTypes.SetUAVisibility:
      return {
        ...state,
        isFiltering: true
      };
    case UAActionTypes.SetUAVisibilityDone:
      return {
        ...state,
        unserviceableAreas: action.payload,
        isFiltering: false
      };
    case UAActionTypes.SaveUA:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isSaving: true
      };
    case UAActionTypes.SaveUASuccess:
      return {
        ...state,
        selectedUA: action.payload,
        errorMessage: '',
        successMessage: 'Unserviceable Area data saved',
        isSaving: false
      };
    case UAActionTypes.SaveUAFail:
      return {
        ...state,
        selectedUA: null,
        errorMessage: action.payload,
        successMessage: '',
        isSaving: false
      };
    case UAActionTypes.DeleteUA:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isDeleting: true
      };
    case UAActionTypes.DeleteUASuccess:
      return {
        ...state,
        errorMessage: '',
        successMessage: 'Unserviceable Area deleted',
        isDeleting: false
      };
    case UAActionTypes.DeleteUAFail:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        isDeleting: false
      };
    case UAActionTypes.GetUnserviceableAreas:
      return {
        ...state,
        errorMessage: '',
        successMessage: '',
        isLoading: false
      };
    case UAActionTypes.GetWithUnserviceableAreasSuccess: 
      return {
        ...state,
        unserviceableAreas: action.unserviceableAreas,
        errorMessage: '',
        successMessage: '',
        isLoading: false
      };
    case UAActionTypes.GetWithUnserviceableAreasFail:
      return {
        ...state,
        errorMessage: action.payload,
        successMessage: '',
        isLoading: false
      };

    default:
      return state;
  }
}
