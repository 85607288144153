// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyA1lxmXN9ZRszNHEB7UdWqx4N5EJAtbqe0',
    authDomain: 'ccwd-pump-station.firebaseapp.com',
    databaseURL: 'https://ccwd-pump-station.firebaseio.com',
    projectId: 'ccwd-pump-station',
    storageBucket: 'ccwd-pump-station.appspot.com',
    messagingSenderId: '335980301263',
    appId: '1:335980301263:web:08ea17bb487e7eab5e20ba'
  },
  API_URL: 'http://124.105.97.187:6692'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
