import { Action } from '@ngrx/store';
import { PressureMonitor, UnserviceableArea } from '@models';

export enum PMPDataActionTypes {
  GetCurrentData = '[PMP Data] Get current PMP data',
  GetCurrentDataSuccess = '[PMP Data] Get current PMP data successfully',
  GetCurrentDataFail = '[PMP Data] Get current PMP data Fail',
  GetDataByParameter = '[PMP Data] Get Data By Parameters',
  SetAllPMPVisibility = '[PMP Data] Show or Hide PMPs',
  SetAllPMPVisibilitySuccess = '[PMP Data] Show or Hide PMPs Successful',
  GetHourlyView = '[PMP Data] Get hourly view',
}

export class GetCurrentData implements Action {
  readonly type = PMPDataActionTypes.GetCurrentData;

  constructor() {}
}

export class GetCurrentDataSuccess implements Action {
  readonly type = PMPDataActionTypes.GetCurrentDataSuccess;

  constructor(public payload: PressureMonitor[]) {}
}

export class GetCurrentDataFail implements Action {
  readonly type = PMPDataActionTypes.GetCurrentDataFail;

  // payload is the error message
  constructor(public payload: string) {}
}

export class GetDataByParameter implements Action {
  readonly type = PMPDataActionTypes.GetDataByParameter;

  constructor(public id: string[], public dStart: string, public dEnd: string) {}
}

export class SetAllPMPVisibility implements Action {
  readonly type = PMPDataActionTypes.SetAllPMPVisibility;

  constructor(public state: boolean) { };
}

export class SetAllPMPVisibilitySuccess implements Action {
  readonly type = PMPDataActionTypes.SetAllPMPVisibilitySuccess;

  constructor(public payload: PressureMonitor[]) { };
}

export class GetHourlyView implements Action {
  readonly type = PMPDataActionTypes.GetHourlyView;

  constructor(public date: string, public time: number) { };
}

export type PMPDataActions = 
  | GetCurrentData 
  | GetCurrentDataSuccess 
  | GetCurrentDataFail 
  | GetDataByParameter
  | SetAllPMPVisibility
  | SetAllPMPVisibilitySuccess
  | GetHourlyView;
