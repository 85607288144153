import { Directive, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Feature, Map } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Draw, Snap } from 'ol/interaction.js';

import { Polygon, Geometry } from 'ol/geom';
import { Style, Fill, Stroke } from 'ol/style';

import { uniqueID } from '@core/helper';
import { CoordinateChanges } from '@models';

const idPrefix = 'editable_polygon_';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[app-editable-polygon], app-editable-polygon',
})
export class EditablePolygonDirective implements OnChanges, OnDestroy {
  @Input() id: string = uniqueID();
  @Input() point: number[] | null = null;
  @Input() additionalData: any = null;
  @Input() rgbColor: string | null = null;
  @Input() strokeColor: string | null = null;
  @Input() isStaged: false;

  @Output() polygonChanged = new EventEmitter<CoordinateChanges>();

  map: Map = null;
  parentVector: VectorSource = null;
  feature: Feature = null;
  snap: Snap;
  draw: Draw;

  constructor() {}

  get featureId(): string {
    return `${idPrefix}_${this.id}`;
  }

  render(vectorSource: VectorSource, map: Map) {
    this.map = map;
    this.parentVector = vectorSource;

    if (this.point) {
      const poly = new Polygon(this.point);
      this.feature = new Feature({
        geometry: poly,
        type: 'poly',
        ...this.additionalData,
      });

      this.feature.setId(this.featureId);

      if (this.rgbColor) {
        const polyStyle = new Style({
          fill: new Fill({
            color: `rgba(${this.rgbColor}, 0.7)`,
          }),
          stroke: new Stroke({
            color: `rgba(${this.strokeColor || this.rgbColor}, 1)`,
            width: 1,
          }),
        });

        this.feature.setStyle(polyStyle);
      }

      this.setStageStyle();

      this.parentVector.addFeature(this.feature);
      this.addGeometryOnChange(this.feature.getGeometry());
    }

    if (!this.isStaged) {
      this.addInteractions();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach((k) => {
      if (!changes[k].firstChange) {
        // react only to recent changes
        switch (k) {
          case 'point':
            // if the point was changed, we need to redraw this
            this.moveLocation();
            break;
          case 'rgbColor':
          case 'strokeColor':
            this.changeColor();
            break;
          case 'additionalData':
            this.changeMetaData();
            break;
          case 'isStaged':
            this.setStagedStatus();
            break;
          default:
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.removeMapInteractions();
  }

  moveLocation() {
    // removing it first from the map and redrawing it
    if (this.parentVector && this.feature) {
      this.parentVector.removeFeature(this.feature);
      this.render(this.parentVector, this.map);
    }
  }

  changeColor() {
    if (this.feature) {
      const style = new Style({
        fill: new Fill({
          color: `rgba(${this.rgbColor}, 0.7)`,
        }),
        stroke: new Stroke({
          color: `rgba(${this.strokeColor || this.rgbColor}, 1)`,
          width: 1,
        }),
      });

      this.feature.setStyle(style);
    }
  }

  setStageStyle() {
    if (this.isStaged) {
      const style = new Style({
        fill: new Fill({
          color: `rgba(255,0,0, 0.7)`,
        }),
        stroke: new Stroke({
          color: `rgba(255,0,0, 1)`,
          width: 1,
        }),
      });

      this.feature.setStyle(style);
    } else {
      this.feature.setStyle(null);
    }
  }

  changeMetaData() {
    if (this.feature) {
      this.feature.setProperties(this.additionalData);
    }
  }

  addInteractions() {
    // this is so we can attach event handlers
    this.draw = new Draw({
      source: this.parentVector,
      type: 'Polygon' as any,
    });

    this.map.addInteraction(this.draw);
    this.snap = new Snap({ source: this.parentVector });
    this.map.addInteraction(this.snap);

    this.draw.on('drawstart', (e) => {
      // prevent multiple points and polygons
      if (this.feature) {
        this.parentVector.removeFeature(this.feature);
      }
    });
    this.draw.on('drawend', (e) => {
      if (!this.feature) {
        // if there was no feature before
        // we add the geometry change handler
        this.addGeometryOnChange(e.feature.getGeometry());
      }

      this.feature = e.feature;
      const coordinates = (this.feature.getGeometry() as Polygon).getCoordinates();
      this.polygonChanged.emit({ id: this.id, coordinates });
    });
  }

  addGeometryOnChange(geometry: Geometry) {
    geometry.on('change', (evt) => {
      this.polygonChanged.emit({ id: this.id, coordinates: (geometry as Polygon).getCoordinates() });
    });
  }

  removeMapInteractions() {
    if (this.map) {
      this.map.removeInteraction(this.draw);
      this.map.removeInteraction(this.snap);
    }
  }

  setStagedStatus() {
    // if this polygon is "staged", remove all the interaction to it
    if (this.isStaged) {
      this.removeMapInteractions();
    } else {
      this.addInteractions();
    }

    this.setStageStyle();
  }
}
