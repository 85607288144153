import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State } from '@state/index'; // Load the reducer
import { user as CurrentUser, isLoggedIn } from '@state/reducers'; // Load the reducer
import * as actions from '@state/actions';
import { User } from '@app/core/models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user: Observable<User>;
  isLoggedIn: Observable<boolean>;

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.user = this.store.pipe(select(CurrentUser));
    this.isLoggedIn = this.store.pipe(select(isLoggedIn));
  }

  signOut() {
    this.store.dispatch(new actions.Logout());
  }
}
