import { Action } from '@ngrx/store';

export enum SettingsActionTypes {
  GetSettings = '[Settings] Get Settings',
  GetSettingsSuccess = '[Settings] Get Settings Success',
  GetSettingsFail = '[Settings] Get Settings Fail',
  SetSettings = '[Settings] Set Setting',
  SetSettingsSuccess = '[Settings] Set Settings Success',
  SetSettingsFail = '[Settings] Set Settings Fail',
}

export class GetSettings implements Action {
  readonly type = SettingsActionTypes.GetSettings;

  constructor() {}
}

export class GetSettingsSuccess implements Action {
  readonly type = SettingsActionTypes.GetSettingsSuccess;

  constructor(public refreshInterval: number, public currentDayAvailableOption: number[]) {}
}

export class GetSettingsFail implements Action {
  readonly type = SettingsActionTypes.GetSettingsFail;

  constructor(public payload: string) {}
}

export class SetSettings implements Action {
  readonly type = SettingsActionTypes.SetSettings;

  constructor(public refreshInterval: number, public currentDayAvailableOption: number[]) {}
}

export class SetSettingsSuccess implements Action {
  readonly type = SettingsActionTypes.SetSettingsSuccess;

  constructor(public refreshInterval: number, public currentDayAvailableOption: number[]) {}
}

export class SetSettingsFail implements Action {
  readonly type = SettingsActionTypes.SetSettingsFail;

  constructor(public payload: string) {}
}

export type SettingsActions =
  | GetSettings
  | GetSettingsSuccess
  | GetSettingsFail
  | SetSettings
  | SetSettingsSuccess
  | SetSettingsFail;
