import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth, User as FBUser } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseAuth } from '@angular/fire';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { User } from '../models/user.model';

/**
 * How the login flow should be done
 * - App authenticate first using google sign in
 * - After signing in, it should check the firebase user node to see if the user is allowed to use the app
 * - It should also load the permisstions separately
 *
 * TODO:
 * - create a user model
 * - have a separate call for the get user permissions
 * - modify the components to use ngrx pattern for the current user (stretch)
 */

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState: FirebaseAuth = null;
  afAuthObj: AngularFireAuth = null;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {
    this.afAuthObj = afAuth;
  }

  googleSignin() {
    const provider = new auth.GoogleAuthProvider();
    return this.afAuth.auth.signInWithPopup(provider);

    // return this.updateUserData(credential.user);
  }

  /**
   * Fetch the user
   */
  getUserPermissions(userId: string): Observable<User> {
    return this.afs.doc<User>(`Users/${userId}`).valueChanges();
  }

  /**
   * This method updates the firestore User entry with the data pulled from google login
   * @param user the user object returned by angular fire auth
   */
  updateUserData({ uid, email, displayName, photoURL }: FBUser): Promise<void> {
    const userRef: AngularFirestoreDocument = this.afs.doc(`Users/${uid}`);
    const data = {
      uid,
      email,
      displayName,
      photoURL
    };

    return userRef.set(data, { merge: true });
  }

  signOut() {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signOut().then(
        () => {
          resolve();
          this.router.navigate(['/login']);
        },
        (reason) => reject(reason)
      );
    });
  }
}
