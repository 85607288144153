export const sliderOptions = [];

for (let index = 0; index < 24; index++) {
  const value = index;
  const timeValue = index < 10 ? `0${index}:00-0${index}:59` : `${index}:00-${index}:59`;
  let legend;

  if (index < 12) {
    legend = index === 0 ? '12am' : `${index}am`;
  }
  else {
    legend = index === 12 ? '12pm' : `${index - 12}pm`;
  }

  sliderOptions.push({value, legend, timeValue});
}