import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { HttpOptionalParams, QueryParams } from './http-service.model';

export const HTTP_BAD_REQUEST = 400;
export const HTTP_CONFLICT = 409;
export const HTTP_UNAUTHORIZED = 401;

@Injectable()
export class HttpService {
  private api: string;
  requestInProgress = false;
  urls = {
    faraday: {
      schedule: `${this.api}/schedule`
    }
  };

  constructor(private httpClient: HttpClient) {
    this.api = environment.API_URL;
  }

  serializeResourceURl(resourceUrl: string, optionalParams: HttpOptionalParams) {
    const { queryParams, urlMultiParams, urlParam } = optionalParams;

    const query = this._getQueryString(queryParams);

    if (urlMultiParams) {
      resourceUrl = this._setUrlMultiParams(resourceUrl, urlMultiParams);
    } else {
      resourceUrl = this._setUrlParam(resourceUrl, urlParam);
    }

    return `${resourceUrl}${query}`;
  }

  get<T>(resourceUrl: string, optionalParams: any = {}): Observable<any> {
    this.requestInProgress = true;

    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);

    return this.httpClient.get<T>(httpUrl, optionalParams).pipe(
      tap(() => (this.requestInProgress = false)),
      catchError((err) => this._handleError(err))
    );
  }

  post(resourceUrl: string, optionalParams: any = {}): Observable<Object> {
    this.requestInProgress = true;
    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);

    return this.httpClient.post(httpUrl, optionalParams.body, optionalParams).pipe(
      tap(() => (this.requestInProgress = false)),
      catchError((err) => this._handleError(err))
    );
  }

  patch(resourceUrl: string, optionalParams: any = {}): Observable<Object> {
    this.requestInProgress = true;
    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);

    return this.httpClient.patch(httpUrl, optionalParams.body, optionalParams).pipe(
      tap(() => (this.requestInProgress = false)),
      catchError((err) => this._handleError(err))
    );
  }

  put(resourceUrl: string, optionalParams: any = {}): Observable<Object> {
    this.requestInProgress = true;
    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);

    return this.httpClient.put(httpUrl, optionalParams.body, optionalParams).pipe(
      tap(() => (this.requestInProgress = false)),
      catchError((err) => this._handleError(err))
    );
  }

  delete(resourceUrl: string, optionalParams: any = {}): Observable<Object> {
    this.requestInProgress = true;

    const httpUrl = this.serializeResourceURl(resourceUrl, optionalParams);

    return this.httpClient.delete(httpUrl).pipe(
      tap(() => (this.requestInProgress = false)),
      catchError((err) => this._handleError(err))
    );
  }

  isRequestInProgress() {
    return this.requestInProgress;
  }

  private _getQueryString(queryParamsList: any): string {
    if (!queryParamsList) {
      return '';
    }

    const queryString = queryParamsList
      .map(({ name, value }: QueryParams) => `${encodeURIComponent(name)}=${encodeURIComponent(value)}`)
      .join('&');
    return `?${queryString}`;
  }

  private _setUrlParam(resourceUrl: string, parameter: number | string) {
    if (!parameter) {
      return resourceUrl;
    }

    return resourceUrl.replace(/\/-(?=\/)?/, `/${parameter}`);
  }

  private _setUrlMultiParams(resourceUrl: string, parameters: string[]) {
    if (!parameters) {
      return resourceUrl;
    }
    return resourceUrl.replace(/\/-(?=\/)?/g, () => `/${parameters.shift()}`);
  }

  private _handleError(error: Response) {
    this.requestInProgress = false;
    let errorMessage = error || 'Server error';

    if (error.status === HTTP_UNAUTHORIZED) {
      // Clear session
      // Go to Home/Login
      errorMessage = 'Session expired';
    }

    return throwError(errorMessage);
  }
}
