import { Directive, Input, ViewChild, ElementRef, SimpleChanges, OnChanges } from '@angular/core';
import { Map, Overlay } from 'ol';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[app-map-popup], app-map-popup' // '[appMapPopup]'
})
export class MapPopupDirective implements OnChanges {
  @ViewChild('.popover', { read: ElementRef, static: true }) tref: ElementRef;
  @Input() isVisible: boolean;
  @Input() coordinates: number[];

  map: Map = null;
  popup: Overlay;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach((k) => {
    //   if (!changes[k].firstChange) {
    this.showHide();
    //   }
    });
  }

  render(map: Map) {
    this.map = map;
    const closer = this.el.nativeElement.childNodes[0].querySelector('.popup-closer') as HTMLElement;

    if (closer) {
      closer.onclick = () => {
        this.hide();
      };
    }

    this.popup = new Overlay({
      element: this.el.nativeElement.childNodes[0]
    });

    this.map.addOverlay(this.popup);
  }

  showHide() {
    if (this.isVisible) {
      // show
      if (this.popup) {
        this.popup.setPosition(this.coordinates);
      }
      else {
        setTimeout(()=> {
          this.popup.setPosition(this.coordinates);
        }, 300);
      }
    } else {
      // hide
      if (this.popup) {
        this.popup.setPosition(undefined);
      }
    }
  }

  hide() {
    if (this.popup) {
      this.popup.setPosition(undefined);
    }
  }
}
