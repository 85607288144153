import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Observable, of, combineLatest } from 'rxjs';
import { mergeMap, catchError, map, withLatestFrom } from 'rxjs/operators';
import * as _ from 'lodash';

import { DataService } from '@services';
import {
  GetSettingsFail,
  SettingsActionTypes,
  GetSettingsSuccess,
  SetSettingsFail,
  SetSettings,
  SetSettingsSuccess,
} from '../actions';
import { State } from '@app/state';
import { PressureMonitor } from '@app/core/models';
import { getPMP, getRefreshInterval, getCurrentDayAvailableOption } from '../reducers';

@Injectable()
export class SettingsEffects {
  constructor(private store: Store<State>, private dataService: DataService, private actions: Actions) {}

  @Effect()
  getSettings: Observable<Action> = this.actions.pipe(
    ofType(SettingsActionTypes.GetSettings),
    mergeMap(() => this.dataService.getSettings()),
    map((response) => {
      return new GetSettingsSuccess(response.refreshInterval, _.uniq(response.currentDayAvailableOption));
    }),
    catchError((err) => of(new GetSettingsFail(err.message)))
  );

  @Effect()
  setSettings: Observable<Action> = this.actions.pipe(
    ofType(SettingsActionTypes.SetSettings),
    map((action: SetSettings) => action),
    mergeMap(async (response) => {
      await this.dataService.setSettings(response.refreshInterval, response.currentDayAvailableOption);

      return response;
    }),
    map((response) => {
      return new SetSettingsSuccess(response.refreshInterval, response.currentDayAvailableOption);
    }),
    catchError((err) => of(new SetSettingsFail(err.message)))
  );
}
