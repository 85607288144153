import { SettingsActionTypes, SettingsActions } from '../actions/settings.actions';
import * as _ from 'lodash';

export const settingsFeatureKey = 'settings';

export interface State {
  refreshInterval: number;
  currentDayAvailableOption: number[];
  isLoading: boolean;
  isSaving: boolean;
  errorMessage: string;
}

export const initialState: State = {
  refreshInterval: 0,
  currentDayAvailableOption: [],
  isLoading: false,
  isSaving: false,
  errorMessage: '',
};

export function reducer(state = initialState, action: SettingsActions): State {
  switch (action.type) {
    case SettingsActionTypes.GetSettings:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case SettingsActionTypes.GetSettingsSuccess:
      return {
        ...state,
        isLoading: false,
        errorMessage: '',
        refreshInterval: action.refreshInterval,
        currentDayAvailableOption: _.uniq(action.currentDayAvailableOption),
      };
    case SettingsActionTypes.GetSettingsFail:
      return {
        ...state,
        isLoading: false,
        refreshInterval: 0,
        currentDayAvailableOption: [],
        errorMessage: action.payload,
      };
    case SettingsActionTypes.SetSettings:
      return {
        ...state,
        isSaving: true,
        isLoading: true,
        errorMessage: '',
        refreshInterval: action.refreshInterval,
        currentDayAvailableOption: _.uniq(action.currentDayAvailableOption),
      };
    case SettingsActionTypes.SetSettingsSuccess:
      return {
        ...state,
        isSaving: false,
        isLoading: false,
        errorMessage: '',
      };
    case SettingsActionTypes.SetSettingsFail:
      return {
        ...state,
        errorMessage: action.payload,
        isLoading: false,
        isSaving: false,
      };
    default:
      return state;
  }
}
