import { createSelector, createFeatureSelector, combineReducers, Action, ActionReducerMap } from '@ngrx/store';

// states
import * as fromRoot from '@app/state';
import * as fromPMP from './pmp.reducers';
import * as fromPMPData from './pmp-data.reducers';
import * as fromAuth from './auth.reducers';
import * as fromUA from './unserviceableAreas.reducers';
import * as fromUser from './user.reducers';
import * as fromSettings from './settings.reducers';

export const pmpFeatureKey = 'pmp';
export const pmpDataFeatureKey = 'pmpData';
export const authFeatureKey = 'auth';
export const uaFeatureKey = 'unserviceableArea';
export const userFeatureKey = 'user';
export const settingsFeatureKey = 'settings';

// Extends the app state to include the account settings feature.
// This is required because account settings feature is lazy loaded.
// So the reference to State cannot be added to CoreState directly.
export interface State extends fromRoot.State {
  [pmpFeatureKey]: fromPMP.State;
  [pmpDataFeatureKey]: fromPMPData.State;
  [uaFeatureKey]: fromUA.State;
  [userFeatureKey]: fromUser.State;
  [settingsFeatureKey]: fromSettings.State;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [pmpFeatureKey]: fromPMP.reducer,
    [pmpDataFeatureKey]: fromPMPData.reducer,
    [uaFeatureKey]: fromUA.reducer,
    [userFeatureKey]: fromUser.reducer,
    [settingsFeatureKey]: fromSettings.reducer
  })(state, action);
}

/**
 * Authentication selectors
 */

const getAuthFeatureState = createFeatureSelector<fromAuth.State>(authFeatureKey);

const getAuthState = createSelector(getAuthFeatureState, (state) => {
  return state;
});

export const user = createSelector(getAuthState, (state) => state.user);
export const isLoggedIn = createSelector(getAuthState, (state) => state.isLoggedIn);
export const isLoggingIn = createSelector(getAuthState, (state) => state.isLoggingIn);
export const loginErrMsg = createSelector(getAuthState, (state) => state.errorMessage);

/**
 * Pressure Monitor selectors
 */
const getPMPFeatureState = createFeatureSelector<State>(pmpFeatureKey);
const getPMPState = createSelector(getPMPFeatureState, (state) => state[pmpFeatureKey]);

export const getPMP = createSelector(getPMPState, (state) => state.pmps);
export const getNewPMP = createSelector(getPMPState, (state) => state.newPmp);
export const getPMPErrorMessage = createSelector(getPMPState, (state) => state.errorMessage);
export const getPMPSuccessMessage = createSelector(getPMPState, (state) => state.successMessage);
export const getPMPLoadingStatus = createSelector(getPMPState, (state) => state.isLoading);
export const getPMPFilterStatus = createSelector(getPMPState, (state) => state.isFiltering);
export const getPMPSavingStatus = createSelector(getPMPState, (state) => state.isSaving);
export const getPMPDeletingStatus = createSelector(getPMPState, (state) => state.isDeleting);

/**
 * PMP Data selectors
 */

const getPMPDataFeatureState = createFeatureSelector<State>(pmpDataFeatureKey);
const getPMPDataState = createSelector(getPMPDataFeatureState, (state) => state[pmpDataFeatureKey]);
export const getPMPData = createSelector(getPMPDataState, (state) => state.pmpData);
export const getPMPDataErrorMessage = createSelector(getPMPDataState, (state) => state.errorMessage);
export const getPMPDataSuccessMessage = createSelector(getPMPDataState, (state) => state.successMessage);
export const getPMPDataLoadingStatus = createSelector(getPMPDataState, (state) => state.isLoading);
export const getPMPDataFilterStatus = createSelector(getPMPDataState, (state) => state.isFiltering);
export const getSelectedPMP = createSelector(getPMPState, (state) => state.selectedPMP);

/**
 * Unserviceable Areas Data selectors
 */

const getUAFeatureState = createFeatureSelector<State>(uaFeatureKey);
const getUADataState = createSelector(getUAFeatureState, (state) => state[uaFeatureKey]);
export const getSelectedUA = createSelector(getUADataState, (state) => state.selectedUA);
export const getUnserviceableAreas = createSelector(getUADataState, (state) => state.unserviceableAreas);


// User Related Selectors

const getUserFeatureState = createFeatureSelector<State>(userFeatureKey);

const getUserState = createSelector(getUserFeatureState, (state) => state[userFeatureKey]);

export const getSelectedUser = createSelector(getUserState, (state) => state.user); 
export const getUsers = createSelector(getUserState, (state) => state.users);
export const getUserIsLoading = createSelector(getUserState, (state) => state.isLoading);
export const getUserIsSaving = createSelector(getUserState, (state) => state.isSaving);
export const getUserErrorMessage = createSelector(getUserState, (state) => state.errorMessage);

const getSettingsFeatureState = createFeatureSelector<fromSettings.State>(settingsFeatureKey);
const getSettingsState = createSelector(getSettingsFeatureState, (state) => state[settingsFeatureKey]);

export const getCurrentDayAvailableOption = createSelector(getSettingsState, (state) => state.currentDayAvailableOption);
export const getRefreshInterval = createSelector(getSettingsState, (state) => state.refreshInterval); 