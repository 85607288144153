import { Directive, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Feature } from 'ol';
import VectorSource from 'ol/source/Vector';
import { Polygon } from 'ol/geom';
import { Style, Fill, Stroke } from 'ol/style';

import { uniqueID } from '@core/helper';
import { MapComponent } from '../abstract/map-component.abstract';

const idPrefix = 'my_polygon_';
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[app-polygon], app-polygon',
})
export class PolygonsDirective implements OnChanges, MapComponent, OnDestroy {
  @Input() id: string = uniqueID();
  @Input() point: number[] | null = null;
  @Input() additionalData: any = null;
  @Input() rgbColor: string | null = null;
  @Input() strokeColor: string | null = null;
  @Input() isHidden = false;

  @Output() polygonClick = new EventEmitter<{ coordinates: any[]; selectedData: any }>();
  // @Output() propertyChange = new EventEmitter<SimpleChanges>();

  parentVector: VectorSource = null;
  feature: Feature = null;

  constructor() {
    // console.log(`${this.featureId}: `, this);
  }

  get featureId(): string {
    return `${idPrefix}_${this.id}`;
  }

  get hasClickListener(): boolean {
    return this.polygonClick.observers && this.polygonClick.observers.length > 0;
  }

  render(vectorSource: VectorSource) {
    this.parentVector = vectorSource;
    if (this.point) {
      const poly = new Polygon(this.point);
      this.feature = new Feature({
        geometry: poly,
        type: 'poly',
        ...this.additionalData,
      });

      this.feature.setId(this.featureId);

      if (this.rgbColor) {
        const polyStyle = new Style({
          fill: new Fill({
            color: `rgba(${this.rgbColor}, ${this.isHidden ? '0' : '0.7'})`,
          }),
          stroke: new Stroke({
            color: `rgba(${this.strokeColor || this.rgbColor}, ${this.isHidden ? '0' : '1'})`,
            width: 1,
          }),
        });

        this.feature.setStyle(polyStyle);
      }

      this.parentVector.addFeature(this.feature);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach((k) => {
      if (!changes[k].firstChange) {
        // react only to recent changes
        switch (k) {
          case 'point':
            // if the point was changed, we need to redraw this
            this.moveLocation();
            break;
          case 'rgbColor':
          case 'strokeColor':
            this.changeColor();
            break;
          case 'additionalData':
            this.changeMetaData();
            break;
          case 'isHidden':
            this.setHidden();
            break;
          default:
            break;
        }
      }
    });
  }

  ngOnDestroy() {
    this.polygonClick.unsubscribe();
  }

  getStyle() {
    return new Style({
      fill: new Fill({
        color: `rgba(${this.rgbColor}, 0.7)`,
      }),
      stroke: new Stroke({
        color: `rgba(${this.strokeColor || this.rgbColor}, 1)`,
        width: 1,
      }),
    });
  }

  moveLocation() {
    // removing it first from the map and redrawing it
    if (this.parentVector && this.feature) {
      this.parentVector.removeFeature(this.feature);
      this.render(this.parentVector);
    }
  }

  changeColor() {
    if (this.feature) {
      const style = this.getStyle();

      this.feature.setStyle(style);
    }
  }

  changeMetaData() {
    if (this.feature) {
      this.feature.setProperties(this.additionalData);
    }
  }

  setHidden() {
    if (this.feature) {
      const iconStyle = this.getStyle();
      this.feature.setStyle(iconStyle);
    }
  }

  onClick(coordinates: number[]) {
    this.polygonClick.emit({ coordinates: coordinates, selectedData: this.additionalData });
  }
}
